export const isArrayLength = (arr: any[]): boolean => Array.isArray(arr) && arr.length > 0;


/**
 * Replaces all occurrences of &sect; with § in the given string.
 *
 * @param {string} input - The input string to replace the entity.
 * @returns {string} - The modified string with &sect; replaced by §.
 */
export const replaceSectionEntity = (input: string): string => {
  return input?.replace(/&sect;/g, "§");
};

export const generateInitialRegulationsList = (checkedItems: number[]) => {
  return checkedItems.reduce((acc, item) => {
    acc[item] = { checked: true };
    return acc;
  }, {} as Record<number, { checked: boolean }>);
};

export const parseUrlToOriginal = (search: string): string | null => {
  // Create a URLSearchParams instance to parse the query string
  const params = new URLSearchParams(search);

  // Get the keyword parameter
  const keyword = params.get("keyword");
  if (!keyword) return null;

  // Replace hyphens with spaces and capitalize each word
  return keyword
    ?.split("-")
    ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    ?.join(" ");
};
