import React, { useState } from "react";
import { useMediaQuery, Drawer, IconButton } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FilterListIcon from "@mui/icons-material/FilterList";

type Props = {};

export default function SearchViewFilters({}: Props) {
  const isMobile = useMediaQuery("(max-width:1024px)");
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const filterContent = (
    <div className="leftSide appliedLeftFilters">
      <div className="filterBlock">
        <div className="filterHead">
          <h2 className="filterTitle">Authority Level</h2>
          <button className="selectAllBtn">Select All</button>
        </div>
        <div className="filterBody">
          <FormGroup>
            <FormControlLabel
              className="mainFilter"
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: "#F66E2F",
                    "&.Mui-checked": {
                      color: "#F66E2F",
                    },
                  }}
                />
              }
              label="Statute (1)"
            />
            <FormControlLabel
              className="mainFilter"
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: "#F66E2F",
                    "&.Mui-checked": {
                      color: "#F66E2F",
                    },
                  }}
                />
              }
              label="Regulation (2)"
            />
            <FormControlLabel
              className="mainFilter"
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: "#F66E2F",
                    "&.Mui-checked": {
                      color: "#F66E2F",
                    },
                  }}
                />
              }
              label="Guidance (63)"
            />
            <FormControlLabel
              className="subFilter"
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: "#F66E2F",
                    "&.Mui-checked": {
                      color: "#F66E2F",
                    },
                  }}
                />
              }
              label="Procedure Hand.. (33)"
            />
            <FormControlLabel
              className="subFilter"
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: "#F66E2F",
                    "&.Mui-checked": {
                      color: "#F66E2F",
                    },
                  }}
                />
              }
              label="Program inform... (21)"
            />
            <FormControlLabel
              className="subFilter"
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: "#F66E2F",
                    "&.Mui-checked": {
                      color: "#F66E2F",
                    },
                  }}
                />
              }
              label="Compliance Gui... (9)"
            />
            <FormControlLabel
              className="subFilter"
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: "#F66E2F",
                    "&.Mui-checked": {
                      color: "#F66E2F",
                    },
                  }}
                />
              }
              label="Program Policy L... (3)"
            />
          </FormGroup>
        </div>
      </div>
      <div className="filterBlock">
        <div className="filterHead">
          <h2 className="filterTitle">Document Format</h2>
          <button className="selectAllBtn">Select All</button>
        </div>
        <div className="filterBody">
          <FormGroup>
            <FormControlLabel
              className="mainFilter"
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: "#F66E2F",
                    "&.Mui-checked": {
                      color: "#F66E2F",
                    },
                  }}
                />
              }
              label="PDF (47)"
            />
            <FormControlLabel
              className="mainFilter"
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: "#F66E2F",
                    "&.Mui-checked": {
                      color: "#F66E2F",
                    },
                  }}
                />
              }
              label="DOC (0)"
            />
            <FormControlLabel
              className="mainFilter"
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: "#F66E2F",
                    "&.Mui-checked": {
                      color: "#F66E2F",
                    },
                  }}
                />
              }
              label="HTML (6)"
            />
            <FormControlLabel
              className="mainFilter"
              control={
                <Checkbox
                  defaultChecked
                  sx={{
                    color: "#F66E2F",
                    "&.Mui-checked": {
                      color: "#F66E2F",
                    },
                  }}
                />
              }
              label="PPT (3)"
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <>
          <IconButton onClick={handleDrawerToggle}>
            <FilterListIcon /> Filter
          </IconButton>
          <Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawerToggle}>
            <div
              style={{
                backgroundColor: "#FFFFFF",
                padding: "16px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              {filterContent}
            </div>
          </Drawer>
        </>
      ) : (
        filterContent
      )}
    </>
  );
}
