import React from "react";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import Skeleton from "@mui/material/Skeleton"; // Import Skeleton from Material-UI
import { REACT_APP_SERVER_DOMAIN } from "../../constants";
import { isArrayLength } from "../../constants/genericHelpers";

interface IAIResponseBlockProps {
  downloadPath: string;
  aiResult: any;
  aiFetching: boolean;
  aiResultId: string;
  aiText: string;
  updateAIFeedback: any;
  feedback: string;
  feedbackAttributeName: string;
  setParentState: any;
  renderSkeleton: any;
  preSearchPrompt: string;
  aiQuotedNameString: string;
}

const renderSourceNodes = (
  source_nodes: any,
  setParentState: any,
  downloadPath: any
) => {
  const uniqueNodes = [] as any[];
  source_nodes.forEach((node: any) => {
    const existing = uniqueNodes.find(
      (x) => x?.source_url === node?.source_url
    );
    if (!existing) {
      uniqueNodes.push(node);
    }
  });
  return uniqueNodes.map((node: any, index: number) => {
    return (
      <span
        key={index}
        onClick={() => {
          const stateObj = {
            documentView: true,
            document:
              REACT_APP_SERVER_DOMAIN +
              downloadPath +
              node?.original_file_name?.replace(/%25/g, "%2525"),
          };
          setParentState(stateObj);
        }}
      >
        {node?.friendly_title} <ArrowForwardOutlinedIcon />
      </span>
    );
  });
};

const SearchViewCoPilot: React.FC<any> = (props: IAIResponseBlockProps) => {
  const {
    aiFetching,
    aiText,
    aiResultId,
    setParentState,
    downloadPath,
    feedbackAttributeName,
    updateAIFeedback,
  } = props;

  return (
    <div className="rightSide rightSideContent">
      <div className="rightSideContentBlock">
        <div className="blockHeader">
          <h2>
            <AutoAwesomeOutlinedIcon sx={{ color: "white" }} /> Copilot
          </h2>
        </div>
        <div className="blockBody">
          {aiFetching ? (
            <>
              <Skeleton
                variant="text"
                width="80%"
                height={30}
                sx={{
                  bgcolor: "#f3f3f39e",
                }}
              />
              <Skeleton
                variant="text"
                width="100%"
                height={30}
                sx={{
                  bgcolor: "#f3f3f39e",
                }}
              />
              <Skeleton
                variant="text"
                width="80%"
                height={30}
                sx={{
                  bgcolor: "#f3f3f39e",
                }}
              />
              <Skeleton
                variant="text"
                width="100%"
                height={30}
                sx={{
                  bgcolor: "#f3f3f39e",
                }}
              />
            </>
          ) : (
            <>
              <p>{aiText}</p>
              <div className="pdfFilesList">
                {isArrayLength(props.aiResult?.source_nodes)
                  ? renderSourceNodes(
                      props.aiResult?.source_nodes,
                      setParentState,
                      downloadPath
                    )
                  : null}
              </div>
            </>
          )}
          {aiResultId && !aiFetching ? (
            <div className="likeDislikeSec">
              <button
                role="button"
                onClick={() => {
                  setParentState({
                    [feedbackAttributeName]: "thumbs_up",
                  });
                  updateAIFeedback({
                    id: aiResultId,
                    user_feedback: "THUMBS_UP",
                    cookie: window.localStorage.cookie,
                  });
                }}
              >
                <ThumbUpAltOutlinedIcon />
                this was helpful
              </button>{" "}
              <button
                role="button"
                onClick={() => {
                  setParentState({
                    [feedbackAttributeName]: "thumbs_down",
                  });
                  updateAIFeedback({
                    id: aiResultId,
                    user_feedback: "THUMBS_DOWN",
                    cookie: window.localStorage.cookie,
                  });
                }}
              >
                <ThumbDownOffAltOutlinedIcon />
                this wasn’t helpful
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SearchViewCoPilot;
