

import { Button, TextField } from "@mui/material/";
import { Form, Formik } from "formik";
import * as React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import StandardUploadInput from "../Shared/SnandardUploadInput";
import { getFileExtension } from "../../utils/general";
import { showErrorMessage, startLoading, stopLoading } from "../../actions/genericAction";
import { createDocumentReference, updateDocumentReference, uploadDocument } from "../../actions/documentActions";


const UpsertDocumentForm: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();

    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const handleSubmitDocument = (selectedFile: File, id?: number | null) => {
        return new Promise(function (resolve, reject) {
            try {
                getFileExtension(selectedFile!.name);
            } catch (e) {
                dispatch(showErrorMessage('Invalid File Extension.'));
                return;
            }
            const uploadData = {
                id,
                file: selectedFile,
                filename: selectedFile.name,
                documentType: props.documentType
            };

            console.log("uploadData", uploadData)
            dispatch(startLoading({}))
            dispatch(
                uploadDocument(
                    uploadData,
                    (data) => {
                        // should I delete doc_id and make it doc_type + 'id'?
                        console.log("success data", data)
                        dispatch(stopLoading({}))
                        dispatch(updateDocumentReference({
                            id,
                            s3_path: `documents/${props.documentType}/${id}`,
                            original_file_name: selectedFile.name,
                            original_file_type: getFileExtension(selectedFile!.name)
                        }, () => {
                            props.onDocumentUploadSuccess(uploadData)
                        }, () => {}, "Document uploaded successfully"))
                    
                    },
                    (data) => {
                        console.log("fail data", data)
                    }
                )
            );
        });
    };

    return (
        <div>
            <Formik
                initialValues={props.initialValues}
                validationSchema={Yup.object().shape({
                    friendlyTitle: Yup.string()
                        .required("Friendly title required"),
                })}
                onSubmit={(values, { setFieldValue }) => {
                    console.log("on submit triggered")
                    if (props.mode === 'update' && selectedFile){
                        handleSubmitDocument(selectedFile, values.id);
                    }
                    else{
                        if (values.id){
                            console.log("values.id", values.id)
                            dispatch(updateDocumentReference({
                                id: values.id,
                                friendly_title: values.friendlyTitle,
                            }, (data) => {
                                console.log("success data", data)
                                props.onDocumentUpdateSuccess()
                            }, (data) => {
                                console.log("failed update data", data)
                            }, 'Document updated successfully'))
                        }
                        else{
                            console.log("would create here");
                            dispatch(createDocumentReference({
                                friendly_title: values.friendlyTitle,
                                document_type: props.documentType,
                            }, (data) => {
                                console.log("success data", data)
                                setFieldValue('id', data?.document_reference?.id); // Update the form value
                                props.onDocumentCreateSuccess()
                            }, (data) => {
                                console.log("fail data", data)
                            }))
                        }

                    }
                }}
            >
                {({ values, handleChange }) => (
                    <Form className="FormContainer">

                        <TextField
                            fullWidth={true}
                            multiline={false}
                            label="Friendly Title"
                            name="friendlyTitle"
                            value={values.friendlyTitle}
                            onChange={handleChange}
                            rows={3}
                            variant="outlined"
                        />
                        {props.mode === 'update' && <StandardUploadInput accept={".pdf"} onFileSelected={(data) => {
                            if (data[0]) {
                                console.log("data[0]", data[0])
                                setSelectedFile(data[0]);

                            }
                        }} />}
                        <Button type={'submit'} variant="outlined">Submit</Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default UpsertDocumentForm;





