export const REACT_APP_WP_DOMAIN = process.env.REACT_APP_WP_DOMAIN;
export const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN;
export const REACT_APP_SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN;
export const REACT_APP_USE_AI = process.env.REACT_APP_USE_AI;
export const SOLR_ADMIN_URL = process.env.SOLR_ADMIN_URL;

export const REGULATIONS_MAP = {
    1: "1   - MSHA Emblem",
    3: "3  - OMB Control Numbers (Paperwork Reduction Act)",
    5: "5  - Fees for Permissibility Testing",
    6: "6 -  Acceptance of Third Party Permissibility Standards",

    7: "7 - Third Party and Applicant Permissibility Testing",

    14: "14 - Flame-Resistant Conveyor Belt Approval",

    15: "15 - Explosives Approval",

    18: "18 - Electrical Permissibility Approval",

    19: "19 - Cap Lamp Permissibility",
    20: "20 - Other Electric Lighting Permissibility",
    22: "22 -  Portable Methane Detector Permissibility",
    23: "23 - Telephone and Signaling Device Permissibility",

    27: "27 – Methane Monitoring System Permissibility",

    28: "28 – Fuses for Trailing Cables Permissibility",

    33: "33 – Drill Dust Collector Permissibility",

    35: "35 – Fire Resistant Hydraulic Fluid Approval",

    36: "36 – Diesel Powered Equipment Permissibility",
    40: "40 - Miners’ Representatives",

    41: "41 - Legal Identity Reports",

    42: "42 - Mine Academy",

    43: "43 - Hazard Complaints under Section 103(g) of the Act",

    44: "44 - Petitions for Modification",
    45: "45- Independent Contractors",
    46: "46 - Training for Shell Dredge, Sand and Gravel, Surface Clay, Colloidal Phosphate and Surface Limestone Mines",

    47: "47 – Hazcom/MSDS",

    48: "48 – Training for all mines not covered under Part 46",

    49: "49 - Mine Rescue",
    50: "50 – Accident Investigations and Reports, Quarterly Employment and Coal Production Reports and Retention Requirements",
    56: "56 - Surface: Metal/Nonmetal",
    57: "57 - Underground: Metal/Nonmetal",
    58: "58 - Metal/NonMetal Health Standards (Abrasive Blasting and Drill Dust Control)",
    62: "62 - Noise",
    70: "70- Health; Underground Coal",
    71: "71 - Health; Surface Coal",
    72: "72 - Health: Coal (Medical Surveillance, Diesel Particulate, Drill Dust. Respiratory Equipment)",

    74: "74 - Coal Mine Dust Sampler Approval",

    75: "75 - Underground: Coal",
    77: "77 - Surface: Coal",
    90: "90 - Coal Miners with CWP",
    100: "100 - Civil Penalty Assessment",
    104: "104 - Pattern of Violations",
  } as any
