import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import Loading from '../General/Loading';
import StandardModal from '../Shared/StandardModal';
import { dateFormatted } from '../../utils/date-utils';
import { tableIcons } from '../Shared/MaterialTableShared';
import { COLORS } from '../Shared/layout-constants';

const client = new HttpClient();
interface IUserTable {
	title: string,
	fixed_query_params: Object
	onEditUser: (user: any) => void
}

const UserTable: React.FC<IUserTable> = (props) => {

	const [processing, setProcessing] = useState(false);
	const [selectedUserData, setSelectedUserData] = useState({} as any)
	const [activationModalOpen, setActivationModalOpen] = useState(false)
	const [userAdminStatusModalOpen, setUserAdminStatusModalOpen] = useState(false);
	const [userTableKey, setUserTableKey] = useState(0);
	const { title } = props;

	const columns = [
		{
			title: 'id', field: 'id', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: ' user_nicename', field: 'user_nicename', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'user_email', field: 'user_email', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'display_name', field: 'display_name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'user_registered', field: 'user_registered', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (row: any) => {
				return (
					<span>{dateFormatted(row.user_registered, "yyyymmdd")}</span>
				)
			}
		},
	];

	return (
		<div>
			<Loading loading={processing} />
			<StandardModal
				title={'Activation Modal'}
				open={activationModalOpen}
				onClose={() => setActivationModalOpen(false)}
				paragraphs={[`Would you like to ${selectedUserData.active ? 'deactivate' : 'activate'} ${selectedUserData.email}?`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setActivationModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						setProcessing(true)
					}
				}]}
				customActions={[]}
			/>
			<StandardModal
				title={'Alter User Admin Status'}
				open={userAdminStatusModalOpen}
				onClose={() => setUserAdminStatusModalOpen(false)}
				paragraphs={[`Would you like to ${selectedUserData.roles?.includes('Admin') ? 'demote' : 'promote'} ${selectedUserData.email} ${selectedUserData.roles?.includes('Admin') ? 'from' : 'to'} Admin Status?`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setUserAdminStatusModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						setProcessing(true);

					}
				}]}
				customActions={[]}
			/>
			<div key={userTableKey} style={{display: 'block'}}>
				<MaterialTable
					icons={tableIcons}
					title={title}
					columns={columns}
					data={((query: any) => new Promise((resolve, reject) => {
						let url = 'api/user/query';

						const params = {
							take: query.pageSize,
							skip: query.page * query.pageSize,
							search: query.search,
							...props.fixed_query_params
						} as any
						if (query.orderBy?.field) {
							params.orderBy = String(query.orderBy.field)
						}
						if (query.orderDirection) {
							params.orderDirection = String(query.orderDirection)
						}
						(async () => {
							try {
								const result = await client.get(url, params);
								if (result.data) {
									resolve({
										data: result.data.data,
										page: query.page,
										totalCount: result.data.total,
									});
								} else {
									reject(new Error('Un-Authorized'));
								}
							} catch (error) {
								reject(error);
							}
						})();
					}) as any)}
					options={{
						sorting: true,
						search: false,
						pageSize: 10,
						pageSizeOptions: [
							10, 25, 50, 100, 200
						],
						paginationAlignment: 'left'
					}}
				/>
			</div>

		</div>

	);
};

export default UserTable;
