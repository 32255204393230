import { Button } from "@mui/material/";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link, Navigate } from "react-router-dom";
import * as Yup from "yup";
import { showErrorMessage } from "../../actions/genericAction";
import { signinAction } from "../../actions/userActions";
import { REACT_APP_WP_DOMAIN } from "../../constants";
import { IRootState } from "../../interfaces/rootState";
import Loading from "../General/Loading";
import { PasswordSetField } from "../PasswordSet/PasswordSetField";
import "./Signin.css";
import { SigninField } from "./SigninField";
// import { UNDER_MAINTENANCE } from "../../../../oshawise-shared/stack-shared/constants/app.constants";

const SignIn: React.FC = (props: any) => {
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const mainState = useSelector((state: IRootState) => state.mainState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.search === "?passwordChanged=true") {
      setChanged(true);
    }
  }, []);

  const signinUser = (values: any) => {
    setLoading(true);
    dispatch(
      signinAction(
        { email: values.email, password: values.password },
        (data) => {
          console.log("onSuccess login", data);
          navigate("/search");
          setLoading(false);
        },
        (err) => {
          console.log("ERRRRR", err.message);
          if (err.message) {
            dispatch(showErrorMessage(err.message));
          } else {
            dispatch(showErrorMessage("Something went wrong."));
          }
          setLoading(false);
        },
        "Welcome to OSHAWise",
        false,
        true
      )
    );
  };

  if (window.localStorage.cookie) {
    return <Navigate replace to="/search" />;
  }

  const checkNavigate = () => {
    if (window.localStorage.cookie) {
      return <Navigate replace to="/search" />;
    }
  };

  checkNavigate();
  const UNDER_MAINTENANCE = false;
  return (
    <>
      <Loading loading={loading} />
      <div className="LoginContainer">
        {UNDER_MAINTENANCE && (
          <div style={{ textAlign: "center", marginTop: 32, marginBottom: 32 }}>
            <Typography variant="h4">
              MSHAwise is currently undergoing maintenance.
            </Typography>
          </div>
        )}

        <Formik
          initialValues={{ email: "" }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Email is invalid")
              .required("Email is required"),
          })}
          onSubmit={(values) => {
            signinUser(values);
          }}
        >
          {({ values, errors, touched }) => (
            <Form className="fixedWidthContainer">
              <div className="formHead">
                <h2>Sign In</h2>
                {changed ? (
                  <div className="passwordReset">
                    A password change was submitted. Please sign in.
                  </div>
                ) : null}
              </div>
              <div className="FormContainer">
                <div className="fields">
                  <label htmlFor="email">Email</label>
                  <Field
                    name="email"
                    placeholder="Email"
                    component={SigninField}
                    className={
                      "form-control" +
                      (errors.email && touched.email ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="fields">
                  <label htmlFor="password">Password</label>
                  <Field
                    name="password"
                    placeholder="Password"
                    component={PasswordSetField}
                  />
                  {mainState?.errorMessage?.error && (
                    <div className="invalid-feedback">
                      {mainState.errorMessage.error}
                    </div>
                  )}
                </div>
                <Button
                  className="signinButton"
                  type="submit"
                  sx={{
                    borderRadius: "5px",
                    display: "flex",
                    width: "100%",
                    padding: "10px 16px",
                    backgroundColor: " #F66E2F",
                    color: "#ffffff",
                    textTransform: "none",
                    boxShadow: "0px 1px 5px 0px #0000001F",
                    "&.Mui-disabled": {
                      background: "#eaeaea",
                      color: "#c0c0c0",
                    },
                  }}
                >
                  Sign In
                </Button>
                <Typography>
                  New to MSHAwise?{" "}
                  <a href={`${REACT_APP_WP_DOMAIN}/pricing/`}>
                    Wise Up <br />
                  </a>
                  <Link to="/forgotPassword">Forgot Password?</Link>
                </Typography>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export interface Props {
  signinInfo: any;
  mainState: any;
}

export interface Values {
  firstName: string;
  lastName: string;
  email: string;
}

export interface State {
  changed: boolean;
}

export default SignIn;