import React, { useState } from "react";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import { useLocation } from "react-router-dom";
import { replaceSectionEntity } from "../../constants/genericHelpers";

interface SearchViewCardProps {
  result: {
    id: string;
    reg_num: string;
    part_num: string;
    friendly_reg_label: string;
    subpart: string;
    reg_title: string;
    reg_body: string;
  };
}

// Helper function to return an icon based on regulation type
export const getIconByRegulationType = (type: string): React.ReactNode => {
  switch (type.toLowerCase()) {
    case "regulations":
      return <AccountBalanceOutlinedIcon />;
    case "guidance":
      return <MenuBookOutlinedIcon />;
    case "case-law":
      return <GavelOutlinedIcon />;
    default:
      return null;
  }
};

const SearchViewCard: React.FC<SearchViewCardProps> = ({ result }) => {
  const location = useLocation();
  const currentPath = location?.pathname?.split("/");
  const currentRegulationType = currentPath?.[currentPath.length - 1];
  const [isExpanded, setIsExpanded] = useState(false);

  // Toggle function for expanding or collapsing text
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  //Render guidance section if current regulation type is 'guidance'
  if (currentRegulationType === "guidance") {
    return (
      <div className="middleContentBox appliedFilterContentBox">
        <h2 className="title">
          <strong>Friendly title</strong>
        </h2>
        <p className="time">
          {new Date().toDateString().split(" ").slice(1).join(" ")}
        </p>
        <p>Guidance - PH</p>
        <p>
          - Handbook Series - PH89-V-1 Coal Mine Health Inspection Procedures -
          Chapter 3 - Noise Coal Mine Health Inspection Procedures Handbook
          ................................... 3.4 3. Sampling Strategy – Coal
          Only........................................................... 3.5 D.
          Pre-Inspection and Post-Inspection Procedures ... Level Meter
          Mode........ 3.9 4. Sound Level Meter – Metal/Nonmetal
          Only....................................... 3.10 F. Inspection
          Documentation - Coal
        </p>
      </div>
    );
  }

  if (currentRegulationType === "regulations" && result?.reg_title) {
    return (
      <div className="middleContentBox appliedFilterContentBox">
        <h2 className="title">
          {result?.reg_title} {getIconByRegulationType(currentRegulationType)}
        </h2>
        <p className="time">
          {replaceSectionEntity(result?.friendly_reg_label)}
        </p>
        <p>{result?.subpart}</p>

        <p>
          {isExpanded ? result?.reg_body : `${result?.reg_body?.slice(0, 400)}`}
          {result?.reg_body?.length > 400 && (
            <span onClick={toggleExpand} className="toggleTextLink">
              {isExpanded ? "Hide" : "Expand"}
            </span>
          )}
        </p>
      </div>
    );
  }

  return <></>;
};

export default SearchViewCard;
