import { createSelector } from 'reselect'
import { IRootState } from '../../interfaces/rootState';
import { IDocumentReferenceType } from '../../stack-shared/interfaces/model/document-reference-type.interface';
import { IDocumentReference } from '../../stack-shared/interfaces/model/document-reference.interface';

// Input Selectors
const getDocumentReferenceTypeState = (state: IRootState) => state.mainState.documentReferenceTypes
const getDocumentReferenceState = (state: IRootState) => state.mainState.document_references

// Output Selectors
export const getDocumentReferenceTypeArray = createSelector([getDocumentReferenceTypeState], (items: any) => Object.values(items) as IDocumentReferenceType[]);
export const getDocumentReferenceArray = createSelector([getDocumentReferenceState], (items: any) => Object.values(items) as IDocumentReference[]);

