import { takeEvery, takeLatest } from "redux-saga/effects";
import { actionIds } from "../../actions/actionIds";
import { genericGet, genericPost, genericSaga } from "./genericSaga";
import solrSearchSaga from "./solrSearchSaga";
import s3Saga from "./s3Saga";

const GET = 'GET';
const POST = 'POST';
const EVERY = 'EVERY';
const LATEST = 'LATEST';

interface SagaConfig {
  actionType: string;
  method: typeof GET | typeof POST; // Add more HTTP methods as needed
  endpoint: string;
  takeEffect?: typeof EVERY | typeof LATEST;
  passthroughAction?: string;
}

export default function* rootSaga() {
  // Static action handlers that don't use generic POST/GET methods
  const staticSagas: Array<[string, (action: any) => void]> = [
    [actionIds.SOLR_SEARCH_FETCH, solrSearchSaga.solrSearchRequest],
    [actionIds.CASE_LAW_SEARCH_FETCH, solrSearchSaga.caseLawSearchRequest],
    [actionIds.SOLR_REGULATIONS_FETCH, solrSearchSaga.solrRegulationsRequest],
    [actionIds.SOLR_QUICK_FETCH, solrSearchSaga.solrQuickRequest],
    [actionIds.SOLR_RELATED_FETCH, solrSearchSaga.solrRelatedRequest],
    [actionIds.USER_ACTION, solrSearchSaga.userPostRequest],
    [actionIds.GET_USER_ACTION, solrSearchSaga.getUserInfoRequest],
    [actionIds.UPDATE_USER_ACTION, solrSearchSaga.updateUserInfoRequest],
    [actionIds.SHOW_ERROR, genericSaga.showError],
    [actionIds.UPLOAD_DOCUMENT, s3Saga.uploadDocument],
    [actionIds.GET_S3_UPLOAD_URL, s3Saga.getS3UploadURL],
    [actionIds.S3_PRESIGNED_URL_UPLOAD, s3Saga.s3PresignedUrlUpload],
    [actionIds.START_LOADING, genericSaga.startLoading],
    [actionIds.STOP_LOADING, genericSaga.stopLoading],
  ];

  // Actions that use generic POST/GET methods
  const sagaConfig: SagaConfig[] = [
    { actionType: actionIds.SEARCH_REGULATIONS, method: POST, endpoint: "api/solr/search-regulations" },
    { actionType: actionIds.SEARCH_GUIDANCE, method: POST, endpoint: "api/solr/search-guidance" },
    { actionType: actionIds.SOLR_QUICK_FETCH, method: POST, endpoint: "api/solr/search_quick_facts" },
    { actionType: actionIds.SIGNIN_ACTION, method: POST, endpoint: "api/auth/login", passthroughAction: actionIds.SIGNIN_SUCCESS },
    { actionType: actionIds.PASSWORD_RESET_ACTION, method: POST, endpoint: "api/user/request-reset-password" },
    { actionType: actionIds.PASSWORD_SET_ACTION, method: POST, endpoint: "api/user/set-password" },
    { actionType: actionIds.UPDATE_BASIC_USER_ACTION, method: POST, endpoint: "api/user/upsert-user-data" },
    { actionType: actionIds.AI_SEARCH, method: POST, endpoint: "api/ai/search", takeEffect: EVERY },
    { actionType: actionIds.UPDATE_AI_FEEDBACK, method: POST, endpoint: "api/ai/feedback" },
    { actionType: actionIds.GET_DOCUMENT_REFERENCES, method: GET, endpoint: "api/document" },
    { actionType: actionIds.GET_ADMIN_DASHBOARD_DATA, method: GET, endpoint: "api/admin/dashboard" },
    { actionType: actionIds.VECTORIZE_DOCUMENT_REFERENCE, method: POST, endpoint: "api/document/vectorize" },
    { actionType: actionIds.INDEX_DOCUMENT, method: POST, endpoint: "api/document/index" },
    { actionType: actionIds.DELETE_DOCUMENT_REFERENCE, method: POST, endpoint: "api/document/delete" },
    { actionType: actionIds.FULL_INDEX, method: POST, endpoint: "api/admin/full-index" },
    { actionType: actionIds.GET_SYSTEM_SETTINGS, method: GET, endpoint: "api/admin/get-system-settings" },
    { actionType: actionIds.UPDATE_SYSTEM_SETTINGS, method: POST, endpoint: "api/admin/update-system-settings" },
    { actionType: actionIds.LOGOUT, method: POST, endpoint: "api/auth/logout" },
    { actionType: actionIds.CREATE_DOCUMENT_REFERENCE, method: POST, endpoint: "api/document/create" },
    { actionType: actionIds.UPDATE_DOCUMENT_REFERENCE, method: POST, endpoint: "api/document/update" },
    { actionType: actionIds.UPDATE_DOCUMENT_REFERENCE_TYPE, method: POST, endpoint: "api/document/update-document-type" },
    { actionType: actionIds.GET_PUBLIC_DATA, method: GET, endpoint: "api/site/public" },
    { actionType: actionIds.INGEST_DOCUMENT, method: POST, endpoint: "api/document/ingest" },
    { actionType: actionIds.SOLR_GET_DOCUMENT_BY_ID, method: GET, endpoint: "api/solr/get-by-id" },
    { actionType: actionIds.MILVUS_GET_BY_ID, method: GET, endpoint: "api/milvus/get-by-id" },
  ];


  // Loop through static saga handlers and bind them with takeLatest or other effects
  for (const [actionType, handler, takeEffect = takeLatest] of staticSagas) {
    yield takeEffect(actionType, handler);
  }

  // Loop through saga configurations to bind generic handlers for GET/POST requests
  for (const { actionType, method, endpoint, takeEffect, passthroughAction } of sagaConfig) {
    const sagaEffect = takeEffect === EVERY ? takeEvery : takeLatest;
    const handler = method === GET ? genericGet : genericPost;

    yield sagaEffect(actionType, (action) =>
      genericSaga.genericHandler(action, (data: any) => handler(data, endpoint), true, passthroughAction)
    );
  }
}
