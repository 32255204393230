import { actionIds } from "./actionIds";

export const genericAction = (actionName: string) => {
    return (payload: any, onSuccess?: (...args: any) => void, onFail?: (...args: any) => void, autoSuccessMessage?: string, autoHandleError?: boolean, autoHandleLoading?: boolean) => ({
      type: actionName,
      payload,
      onSuccess: onSuccess || null,
      onFail: onFail || null,
      autoSuccessMessage,
      autoHandleError,
      autoHandleLoading
    }
    );
  };

export const showErrorMessage = genericAction (actionIds.SHOW_ERROR);
export const startLoading = genericAction (actionIds.START_LOADING);
export const stopLoading = genericAction (actionIds.STOP_LOADING);
export const setAIResultAction = genericAction (actionIds.SET_AI_RESULT);
export const setAIResultCaseLawAction = genericAction (actionIds.SET_AI_RESULT_CASE_LAW);
export const mergeAIResultAction = genericAction (actionIds.MERGE_AI_RESULT);
export const mergeAIResultCaseLawAction = genericAction (actionIds.MERGE_AI_RESULT_CASE_LAW);
export const clearAIResultAction = genericAction (actionIds.CLEAR_AI_RESULT);
export const clearAIResultCaseLawAction = genericAction (actionIds.CLEAR_AI_RESULT_CASE_LAW);
export const getPublicData = genericAction (actionIds.GET_PUBLIC_DATA);
