import React from "react";
import SearchViewFilters from "./SearchViewFilters";
import SearchViewResultsPanel from "./SearchViewResultsPanel";
import "./SearchView.css";
import RenderQuickFacts from "./RenderQuickFacts";

interface SearchViewContentProps {
  solrResultsItems: any;
  totalItems: number;
  searchTerm: string;
  renderCoPilot: React.ReactNode;
  itemsPerPage: number;
  setItemsPerPage: (value: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const SearchViewContent: React.FC<SearchViewContentProps> = ({
  solrResultsItems,
  totalItems,
  searchTerm,
  renderCoPilot,
  itemsPerPage,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <div className="searchViewSkeleton">
      <SearchViewFilters />
      <div className="mainContent">
      {renderCoPilot}
      <SearchViewResultsPanel
        searchTerm={searchTerm}
        totalItems={totalItems}
        solrResultsItems={solrResultsItems}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <RenderQuickFacts searchTerm={searchTerm} />
    </div>
  );
};

export default SearchViewContent;