import SearchIcon from "@mui/icons-material/Search";
import { Box, LinearProgress } from "@mui/material";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";
import { actionIds } from "../../actions/actionIds";
import {
  caseLawSearchAction,
  searchGuidance,
  searchRegulations,
  solrQuickAction,
  solrRegulationsAction,
  solrSearchAction,
} from "../../actions/solrActions";
import { REACT_APP_SERVER_DOMAIN, REGULATIONS_MAP } from "../../constants";
import { IRootState } from "../../interfaces/rootState";
import Checkboxes from "../Checkboxes/Checkboxes";
import SolrResultList from "../List/SolrResultList";
import AIResponseBlock from "./AIResponseBlock";
import SearchViewTabs from "./SearchViewTabs";
import {
  searchAIAction,
  updateAIFeedbackAction,
} from "../../actions/aiActions";
import { MARGIN_MEDIUM } from "../Shared/layout-constants";
import {
  clearAIResultAction,
  clearAIResultCaseLawAction,
  setAIResultAction,
  setAIResultCaseLawAction,
} from "../../actions/genericAction";
import { v4 as uuidv4 } from "uuid";
import { UNDER_MAINTENANCE } from "../../stack-shared/constants/app.constants";

import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";

import "./SearchView.css";
import SearchViewContent from "./SearchViewContent";
import SearchViewSkeleton from "./SearchViewSkeleton";
import { aiMockResult, solrResult } from "./dummy";
import {
  generateInitialRegulationsList,
  isArrayLength,
  parseUrlToOriginal,
} from "../../constants/genericHelpers";
import SearchTabs from "./SearchTabs";
import SearchViewCoPilot from "./SearchViewCoPilot";

enum SECTION_TAB {
  REGULATIONS = 0,
  DOCUMENTS = 1,
  CASE_LAW = 2,
}

interface Paging {
  start: number;
  rows: number;
}

const checkAuthentication = () => {
  try {
    // Get the 'cookie' and 'expires' values from localStorage
    const cookie = window.localStorage.getItem("cookie");
    const expires = window.localStorage.getItem("expires");

    // If either value is missing, clear localStorage and navigate to home
    if (!cookie || !expires) {
      // console.log("No valid cookie or expiration found");
      clearStorage();
      return <Navigate replace to="/" />;
    }

    // Check if the current date is past the expiration date
    const expirationDate = new Date(expires);
    if (new Date() > expirationDate) {
      // console.log("Cookie has expired");
      clearStorage();
      return <Navigate replace to="/" />;
    }

    // If the checks pass, you can proceed with the app logic
    return null; // No navigation needed, proceed normally
  } catch (error) {
    console.error("Error checking authentication:", error);
    clearStorage();
    return <Navigate replace to="/" />;
  }
};

// Helper function to clear specific items from localStorage
const clearStorage = () => {
  window.localStorage.removeItem("cookie");
  window.localStorage.removeItem("expires");
};

const reduceFilters = (filter: any) => {
  return Object.keys(filter).reduce((out: any, key: any) => {
    if (filter[key]?.checked) {
      out.push(key);
    }
    return out;
  }, []);
};

// Helper function to select the appropriate solr results
const getSolrResultsItems = (
  regulationsData: { docs?: any[] },
  guidanceData: { docs?: any[]; data?: any[] }
): any[] => {
  if (regulationsData?.docs?.length) {
    return regulationsData.docs;
  } else if (guidanceData?.docs?.length) {
    return guidanceData.docs;
  }
  return []; // Default to an empty array if no valid data
};

const SearchView: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const mainState = useSelector((state: IRootState) => state.mainState);
  const [entityPerson, setEntityPerson] = useState([] as any[]);
  const [entityLocation, setEntityLocation] = useState([] as any[]);
  const [entityOrganization, setEntityOrganization] = useState([] as any[]);

  const [entityPersonCaseLaw, setEntityPersonCaseLaw] = useState([] as any[]);
  const [entityLocationCaseLaw, setEntityLocationCaseLaw] = useState(
    [] as any[]
  );
  const [entityOrganizationCaseLaw, setEntityOrganizationCaseLaw] = useState(
    [] as any[]
  );

  const [initialSearchCount, setInitialSearchCount] = useState(0);
  const [quickFactsSearchCount, setQuickFactsSearchCount] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");
  const debounceTimeoutRef = React.useRef<number | undefined>(undefined); // Use a ref to store the debounce timeout

  const [searchedTerm, setSearchedTerm] = useState("");
  const [documentState, setDocumentState] = useState(null as any);
  const [documentView, setDocumentView] = useState(false);
  const [miningMethod, setMiningMethod] = useState(["Surface", "Underground"]);
  const [commodity, setCommodity] = useState(["Coal", "Metal", "Nonmetal"]);

  const initialAuthorityLevel = [
    { title: "Guidance", checked: true },
    { title: "Statute", checked: true },
    { title: "Regulation", checked: true },
  ];

  const initialSubGuidance = [
    { title: "ph", checked: true },
    { title: "ppl", checked: true },
    { title: "pil", checked: true },
    { title: "ppm", checked: true },
    { title: "pib", checked: true },
    { title: "faq", checked: true },
    { title: "cg", checked: true },
    { title: "na", checked: true },
  ];

  const initialDocType = [
    { title: "pdf", checked: true },
    { title: "html", checked: true },
    { title: "ppt", checked: true },
    { title: "doc", checked: true },
  ];

  // Use the function to generate the regulations list
  const initialRegulationsList = generateInitialRegulationsList([
    1, 3, 5, 6, 7, 14, 15, 18, 19, 20, 22, 23, 27, 28, 33, 35, 36, 40, 41, 42,
    43, 44, 45, 46, 47, 48, 49, 50, 56, 57, 58, 62, 70, 71, 72, 74, 75, 77, 90,
    100, 104,
  ]);

  const [regulationsList, setRegulationsList] = useState({
    ...initialRegulationsList,
  });
  const [sort, setSort] = useState("best");
  const [numOfResults, setNumOfResults] = useState(10);
  const [offset, setOffset] = useState(0);
  const [caseLawNumOfResults, setCaseLawNumOfResults] = useState(10);
  const [caseLawOffset, setCaseLawOffset] = useState(0);
  const [documentType, setDocumentType] = useState([...initialDocType]);
  const [oneSearch, setOneSearch] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchingCaselaw, setIsSearchingCaseLaw] = useState(false);
  const [regListItems, setRegListItems] = useState([] as any[]);

  const [aiResultId, setAiResultId] = useState(null as any);
  const [aiResult, setAIResult] = useState(null as any);

  const [showFilters, setShowFilters] = useState(false);
  const useAI = true; // safeBool(REACT_APP_USE_AI || true);

  const [aiFetching, setAiFetching] = useState(false);
  const [aiCaseLawFetching, setAiCaseLawFetching] = useState(false);
  const [feedback, setFeedback] = useState(null as any);
  const [feedbackCaseLaw, setFeedbackCaseLaw] = useState(null as any);
  const [aiResultCaseLawId, setAiResultCaseLawId] = useState(null as any);
  const [sectionTab, setSectionTab] = useState(SECTION_TAB.REGULATIONS);

  const [authorityLevel, setAuthorityLevel] = useState([
    ...initialAuthorityLevel,
  ]);
  const [subGuidance, setSubGuidance] = useState([...initialSubGuidance]);
  const [forceSearch, setForceSearch] = useState(false);

  const [regulationsData, setRegulationsData] = useState([] as any);
  const [guidanceData, setGuidanceData] = useState([] as any);

  const socketId = useSelector((state: IRootState) => state.mainState.socketId);

  const aiResultCaseLawState = useSelector(
    (state: IRootState) => state.mainState.aiResultCaseLaw
  );

  const queryParams = new URLSearchParams(window.location.search);
  const useai_param = queryParams.get("useai");

  const singleDocView = (item: any, context = "documents") => {
    setDocumentState(
      REACT_APP_SERVER_DOMAIN + "/" + context + "/" + item.original_file_name
    );
    setDocumentView(true);
  };

  const backToSearch = () => {
    setDocumentView(false);
  };

  useEffect(() => {
    if (initialSearchCount !== 0) {
      if (offset > 0 || caseLawOffset > 0) {
        search(sectionTab, false);
      } else {
        // Search both docs and case law
        search(null, false);
      }
    }
  }, [
    sort,
    commodity,
    miningMethod,
    numOfResults,
    caseLawNumOfResults,
    offset,
    caseLawOffset,
  ]);

  useEffect(() => {
    const original_file_type =
      mainState?.solrResults?.facet_counts?.facet_fields?.original_file_type;
    if (original_file_type) {
      const updatedDocumentType = original_file_type.map((x: any) => {
        const existingDocType = documentType.find(
          (y: any) => x.title === y.title
        );

        return {
          title: x.title,
          count: x.count || 0,
          checked: existingDocType?.checked || true,
        };
      });
      setDocumentType(updatedDocumentType);
    }
  }, [mainState?.solrResults?.facet_counts?.facet_fields?.original_file_type]);

  useEffect(() => {
    const authority_level =
      mainState?.solrResults?.facet_counts?.facet_fields?.authority_level;
    if (authority_level) {
      const updatedAuthorityLevel = authority_level.map((x: any) => {
        const existingAuthorityLevel = authorityLevel.find(
          (y: any) => x.title === y.title
        );

        return {
          title: x.title,
          count: x.count || 0,
          checked: existingAuthorityLevel?.checked || true,
        };
      });
      setAuthorityLevel(updatedAuthorityLevel);
    }

    const guidance_type =
      mainState?.solrResults?.facet_counts?.facet_fields?.guidance_type;
    if (guidance_type) {
      const updatedGuidanceType = guidance_type.map((x: any) => {
        const existingGuidanceType = subGuidance.find(
          (y: any) => x.title === y.title
        );

        return {
          title: x.title,
          count: x.count || 0,
          checked: existingGuidanceType?.checked || true,
        };
      });
      setSubGuidance(updatedGuidanceType);
    }
  }, [
    mainState?.solrResults?.facet_counts?.facet_fields?.authority_level,
    mainState?.solrResults?.facet_counts?.facet_fields?.guidance_type,
  ]);

  useEffect(() => {
    if (initialSearchCount !== 0) {
      if (forceSearch) {
        if (offset > 0 || caseLawOffset > 0) {
          search(sectionTab, false);
        } else {
          // Search both docs and case law
          search(null, false);
        }
      }
      setForceSearch(false);
    }
  }, [forceSearch]);

  const updateEntityResults = (
    entityKey: string,
    resultSet: any,
    setStateFunction: any,
    existingEntities: any
  ) => {
    const results = resultSet?.[entityKey];
    if (results) {
      setStateFunction(
        results.map(({ title, count }: { title: string; count: number }) => {
          const existing = existingEntities.find(
            (entity: any) => entity.title === title
          );
          return {
            title,
            count,
            checked: existing ? existing.checked : true,
          };
        })
      );
    }
  };

  useEffect(() => {
    updateEntityResults(
      "entity_person",
      mainState.solrResults,
      setEntityPerson,
      entityPerson
    );
    updateEntityResults(
      "entity_location",
      mainState.solrResults,
      setEntityLocation,
      entityLocation
    );
    updateEntityResults(
      "entity_organization",
      mainState.solrResults,
      setEntityOrganization,
      entityOrganization
    );
    updateEntityResults(
      "reg_body",
      mainState.solrResults,
      setEntityOrganization,
      entityOrganization
    );
    updateEntityResults(
      "reg_title",
      mainState.solrResults,
      setEntityOrganization,
      entityOrganization
    );
  }, [mainState.solrResults]);

  useEffect(() => {
    updateEntityResults(
      "entity_person",
      mainState.caseLawResults,
      setEntityPersonCaseLaw,
      entityPersonCaseLaw
    );
    updateEntityResults(
      "entity_location",
      mainState.caseLawResults,
      setEntityLocationCaseLaw,
      entityLocationCaseLaw
    );
    updateEntityResults(
      "entity_organization",
      mainState.caseLawResults,
      setEntityOrganizationCaseLaw,
      entityOrganizationCaseLaw
    );
  }, [mainState.caseLawResults]);

  useEffect(() => {
    if (initialSearchCount !== 0) {
      regulationsSearch();
    }
  }, [regulationsList]);

  useEffect(() => {
    if (initialSearchCount !== 0) {
      initialSearch();
    }
  }, [initialSearchCount]);

  useEffect(() => {
    checkAuthentication();

    // const handleKeyPress = (event: any) => {
    //   if (event.key === "Enter" || event.keyCode === 13) {
    //     searchButton();
    //   }
    // };

    // // Attach the event listener when the component mounts
    // document.addEventListener("keydown", handleKeyPress);

    // // Clean up the event listener when the component unmounts
    // return () => {
    //   document.removeEventListener("keydown", handleKeyPress);
    // };
  }, []);

  const updateSort = (updated: any) => {
    setSort(updated);
  };

  const updateCommodity = (list: string[]) => {
    setCommodity(list);
  };

  const updateMining = (list: string[]) => {
    setMiningMethod(list);
  };

  const updateNumOfResults = (updated: any) => {
    if (numOfResults !== updated) {
      setNumOfResults(updated);
    }
  };

  const updateCaseLawNumOfResults = (updated: any) => {
    setCaseLawNumOfResults(updated);
  };

  const updateAuthorityLevel = (list: any) => {
    setForceSearch(true);
    setAuthorityLevel([...list]);
  };

  const updateGuidance = (list: any) => {
    setForceSearch(true);
    setSubGuidance([...list]);
  };

  const updateDocType = (list: any) => {
    setForceSearch(true);
    setDocumentType([...list]);
  };

  const updateRegsType = (list: any) => {
    setRegulationsList({ ...list });
  };

  const updatePage = (updated: any) => {
    setOffset(updated);
  };

  const caseLawUpdatePage = (updated: any) => {
    setCaseLawOffset(updated);
  };

  const updateEntityPerson = (list: any) => {
    setForceSearch(true);
    setEntityPerson(list);
  };

  const updateEntityLocation = (list: any) => {
    setForceSearch(true);
    setEntityLocation(list);
  };

  const updateEntityOrganization = (list: any) => {
    setForceSearch(true);
    setEntityOrganization(list);
  };

  const updateEntityPersonCaseLaw = (list: any) => {
    setForceSearch(true);
    setEntityPersonCaseLaw(list);
  };

  const updateEntityLocationCaseLaw = (list: any) => {
    setForceSearch(true);
    setEntityLocationCaseLaw(list);
  };

  const updateEntityOrganizationCaseLaw = (list: any) => {
    setForceSearch(true);
    setEntityOrganizationCaseLaw(list);
  };

  const resetAllEntities = () => {
    setEntityPerson([]);
    setEntityLocation([]);
    setEntityOrganization([]);
    setEntityPersonCaseLaw([]);
    setEntityLocationCaseLaw([]);
    setEntityOrganizationCaseLaw([]);
    setAuthorityLevel([...initialAuthorityLevel]);
    setSubGuidance([...initialSubGuidance]);
    setDocumentType([...initialDocType]);
    setRegulationsList({ ...initialRegulationsList });
    if (initialSearchCount > 0) {
      setForceSearch(true);
    }
  };

  const parsedSearchKeyword = parseUrlToOriginal(location?.search) || "";

  const currentPath = location?.pathname?.split("/");
  const currentRegulationType = currentPath?.[currentPath.length - 1];

  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Separate initial search triggered by parsedSearchKeyword
  useEffect(() => {
    if (parsedSearchKeyword && !aiFetching) {
      setSearchTerm(parsedSearchKeyword);
      searchButton(parsedSearchKeyword); // Only for initial search
    }
  }, [parsedSearchKeyword]);

  // Trigger onFetchRegsAndDocs when `currentPage` or `itemsPerPage` change
  useEffect(() => {
    onFetchRegsAndDocs(searchTerm, {
      start: (currentPage - 1) * itemsPerPage,
      rows: itemsPerPage,
    });
    onSearchQueryWithAI();
  }, [currentPage, itemsPerPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page); // Update page without resetting to 1
    typeof window !== "undefined" &&
      window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleItemsPerPageChange = (value: number) => {
    setItemsPerPage(value);
    setCurrentPage(1); // Reset to page 1 only when items per page changes
    typeof window !== "undefined" &&
      window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const updateSearchQuery = (searchValue: string) => {
    setSearchTerm(searchValue); // Update the search term state

    // Get the current search params
    const searchParams = new URLSearchParams(location.search);

    // Update or set the `keyword` query param
    if (searchValue) {
      searchParams.set("keyword", searchValue);
    } else {
      searchParams.delete("keyword"); // Remove the param if the input is empty
    }

    // Push the updated URL with the new search params
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const reduceFiltersFacetList = (filters: any) => {
    let checkedFiltered = filters.filter((x: any) => x.checked);
    return checkedFiltered.map((x: any) => x.title);
  };

  const onSearchQueryWithAI = async () => {
    const uuid = uuidv4();
    setAiFetching(true);

    const hasKeyword = searchTerm || parsedSearchKeyword;

    if (!hasKeyword) {
      return;
    }

    await new Promise((resolve, reject) => {
      dispatch(
        searchAIAction(
          {
            query: hasKeyword,
            document_type: "GUIDANCE",
            cookie: window.localStorage.cookie,
            socketId,
            uuid,
            stream: true,
          },
          (data: any) => {
            if (data.uuid === uuid) {
              dispatch(
                setAIResultAction({ ...data.result, compare_uuid: uuid })
              );
              setAiResultId(data.aiResultId);
              setAIResult(data.result);
            }
            resolve(data);
          },
          (error) => {
            console.error("Error in AI search:", error);
            reject(error);
          }
        )
      );
    });
    setAiFetching(false);
  };

  const resetEntitiesStates = () => {
    setRegulationsData([]);
    setGuidanceData([]);
  };

  const onFetchRegsAndDocs = async (
    searchKey: string,
    paging: Paging = { start: 0, rows: 10 }
  ) => {
    //Reset old state before fetching new data
    resetEntitiesStates();

    const search = searchKey || searchTerm || parsedSearchKeyword;
    setIsSearching(true);

    // Helper functions for each search action
    const searchRegulationsAsync = () =>
      new Promise((resolve) => {
        dispatch(
          searchRegulations({ search, paging }, (data) => {
            setRegulationsData(data?.data?.response);
            resolve(data);
          })
        );
      });

    const searchGuidanceAsync = () =>
      new Promise((resolve) => {
        dispatch(
          searchGuidance({ search, paging }, (data) => {
            setGuidanceData(data?.response);
            resolve(data);
          })
        );
      });

    // Run the first two search actions in parallel
    await Promise.all([searchRegulationsAsync(), searchGuidanceAsync()]);
    setIsSearching(false);
  };

  const searchButton = async (searchKey: string) => {
    try {
      await onFetchRegsAndDocs(searchKey, {
        start: (currentPage - 1) * itemsPerPage,
        rows: itemsPerPage,
      });

      // Start the AI search, indicating it's fetching
      await onSearchQueryWithAI();
    } catch (error) {
      console.error(`Error while searching regs, docs and AI`);
    }
  };

  const search = (
    activeTab: SECTION_TAB | null = null,
    isInitial: boolean = false
  ) => {
    // IMPLEMENT use state.sectionTab
    searchCore(activeTab, isInitial);
    setSearchTerm(searchTerm);
    setOneSearch(true);
  };

  const aiSearchCaseLaw = () => {
    return new Promise((resolve, reject) => {
      setAiCaseLawFetching(true);
      setFeedbackCaseLaw(null);
      const uuid = uuidv4();
      setAiResultCaseLawId(null);
      dispatch(clearAIResultCaseLawAction({ uuid }));
      dispatch(
        searchAIAction(
          {
            query: searchTerm,
            document_type: "CASE_LAW",
            cookie: window.localStorage.cookie,
            socketId,
            uuid,
            stream: true,
          },
          (data: any) => {
            if (data.uuid === uuid) {
              dispatch(
                setAIResultCaseLawAction({ ...data.result, compare_uuid: uuid })
              );
              setAiResultCaseLawId(data.aiResultId);
              setAiCaseLawFetching(false);
            }

            resolve({});
          },
          (e) => {
            setAiCaseLawFetching(false);
            // console.log("error case law", e);
            resolve({});
          }
        )
      );
    });
  };

  function wait(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function shouldUseAI() {
    if (useAI && useai_param !== "false") {
      return true;
    } else {
      return false;
    }
  }

  const initialSearch = () => {
    searchCore(null, true);
    dispatch({ type: actionIds.CLEAR_SEARCH_CACHE });
    if (shouldUseAI()) {
      aiSearchCaseLaw();
    }
    setSearchedTerm(searchTerm);
    setOneSearch(true);
    setQuickFactsSearchCount(quickFactsSearchCount + 1);
  };

  const quickFactsSearch = () => {
    dispatch(
      solrQuickAction(
        {
          search: searchTerm || parsedSearchKeyword,
        },
        () => {
          regulationsSearch();
        },
        () => {}
      ) as any
    );
  };

  const regulationsSearch = async () => {
    const regSearchParams = {
      search: searchTerm,
      filters: {
        reg_num: reduceFilters(regulationsList),
      },
    };

    const handleResults = (results: any) => {
      const rawItems = results?.facet_counts?.facet_fields?.reg_num || [];
      const filteredItems = rawItems
        .filter((item: any) => item.count > 0)
        .map((item: any) => ({
          title: item.title,
          label: REGULATIONS_MAP[item.title] || item.title,
          count: item.count,
          checked: true,
        }));

      setRegListItems(filteredItems);
    };

    await dispatch(
      solrRegulationsAction(regSearchParams, handleResults, () => {}) as any
    );
  };

  const searchSolrCore = (isInitial = false) => {
    return new Promise((resolve, reject) => {
      let filters = {
        authority_level: reduceFiltersFacetList(authorityLevel),
        guidance_type: reduceFiltersFacetList(subGuidance),
        original_file_type: reduceFiltersFacetList(documentType),
        commodity,
        mining_method: miningMethod,
      } as any;

      // extract filters don't make sense across multiple searches, but can be used within a search
      if (true) {
        if (entityPerson.length) {
          const allPersonChecked = entityPerson.every(
            (x: any) => x.checked === true
          );
          filters.entity_person = allPersonChecked
            ? []
            : entityPerson
                .filter((x: any) => x.checked === true)
                .map((x: any) => encodeURIComponent(x.title));
          // filters.entity_person = entityPerson.filter((x: any) => x.checked === true).map((x: any) => encodeURIComponent(x.title));
          // filters.entity_person = filters.entity_person.map((x: any) => x.replace('.', '*'))
        }
        if (entityLocation.length) {
          const allLocationChecked = entityLocation.every(
            (x: any) => x.checked === true
          );
          filters.entity_location = allLocationChecked
            ? []
            : entityLocation
                .filter((x: any) => x.checked === true)
                .map((x: any) => encodeURIComponent(x.title));
        }
        if (entityOrganization.length) {
          const allOrganizationChecked = entityOrganization.every(
            (x: any) => x.checked === true
          );
          filters.entity_organization = allOrganizationChecked
            ? []
            : entityOrganization
                .filter((x: any) => x.checked === true)
                .map((x: any) => encodeURIComponent(x.title));
        }
      }

      const searchParams = {
        is_initial: isInitial,
        socketId,
        search: encodeURIComponent(searchTerm),
        sort,
        fuzzy: 1,
        filters,
        paging: {
          start: offset,
          rows: numOfResults,
        },
      };

      dispatch(
        solrSearchAction(
          searchParams,
          (result: any) => {
            // problem is can't set it here or infinit loop, if I don't set it here, how do we set it at all, maybe a field initial values?
            // try setting state to props in initializer.,....

            setIsSearching(false);
            // setTotalSearchCount(totalSearchCount + 1);
            resolve(result);
          },
          () => {}
        ) as any
      );
    });
  };

  const searchCaseLawCore = (isInitial = false) => {
    return new Promise((resolve, reject) => {
      let filters = {} as any;
      // extract filters don't make sense across multiple searches, but can be used within a search
      if (!isInitial) {
        if (entityPersonCaseLaw.length) {
          const allPersonChecked = entityPersonCaseLaw.every(
            (x: any) => x.checked === true
          );
          filters.entity_person = allPersonChecked
            ? []
            : entityPersonCaseLaw
                .filter((x: any) => x.checked === true)
                .map((x: any) => encodeURIComponent(x.title));
          // filters.entity_person = entityPersonCaseLaw.filter((x: any) => x.checked === true).map((x: any) => encodeURIComponent(x.title));
          // filters.entity_person = filters.entity_person.map((x: any) => x.replace('.', '*'))
        }
        if (entityLocationCaseLaw.length) {
          const allLocationChecked = entityLocationCaseLaw.every(
            (x: any) => x.checked === true
          );
          filters.entity_location = allLocationChecked
            ? []
            : entityLocationCaseLaw
                .filter((x: any) => x.checked === true)
                .map((x: any) => encodeURIComponent(x.title));
        }
        if (entityOrganizationCaseLaw.length) {
          const allOrganizationChecked = entityOrganizationCaseLaw.every(
            (x: any) => x.checked === true
          );
          filters.entity_organization = allOrganizationChecked
            ? []
            : entityOrganizationCaseLaw
                .filter((x: any) => x.checked === true)
                .map((x: any) => encodeURIComponent(x.title));
        }
      }

      const searchParams = {
        is_initial: isInitial,
        socketId,
        search: searchTerm,
        sort,
        fuzzy: 1,
        filters,
        paging: {
          start: caseLawOffset,
          rows: caseLawNumOfResults,
        },
      };
      dispatch(
        caseLawSearchAction(
          searchParams,
          (result: any) => {
            setIsSearchingCaseLaw(false);
            resolve(result);
          },
          () => {}
        ) as any
      );
    });
  };

  const searchCore = (
    searchTab: SECTION_TAB | null = null,
    isInitial = false
  ) => {
    if (isSearching || isSearchingCaselaw) {
      return;
    }
    // queue solr searches rather then parallel
    if (
      sectionTab === SECTION_TAB.DOCUMENTS ||
      sectionTab === SECTION_TAB.REGULATIONS
    ) {
      searchSolrCore(isInitial).then(() => {
        if (searchTab == null) {
          searchCaseLawCore(isInitial);
        }
      });
      setIsSearching(true);
    } else {
      searchCaseLawCore(isInitial).then(() => {
        if (searchTab == null) {
          searchSolrCore(isInitial);
        }
      });
      setIsSearchingCaseLaw(true);
    }
  };

  const setMyState = (obj: any) => {
    for (const key in obj) {
      switch (key) {
        case "documentView":
          setDocumentView(obj[key]);
          break;
        case "document":
          setDocumentState(obj[key]);
          break;
        case "feedback":
          setFeedback(obj[key]);
          break;
        case "feedbackCaseLaw":
          setFeedbackCaseLaw(obj[key]);
      }
    }
  };

  return (
    <div className="searchViewWrapper">
      <div className="searchViewHeader">
        <div className="contentWidth">
          <div className="search">
            <div className="searchLeft">
              <InputBase
                placeholder="Search…"
                classes={{
                  root: "inputRoot",
                  input: "inputInput",
                }}
                value={searchTerm || " "}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    updateSearchQuery(searchTerm);
                  }
                }}
              />
            </div>
            <div
              className="searchRight"
              onClick={() => updateSearchQuery(searchTerm)}
            >
              <SearchIcon />
            </div>
            <div
              className="searchRight"
              onClick={() => setShowFilters(!showFilters)}
            >
              <TuneRoundedIcon />
            </div>
          </div>
          {showFilters ? (
            <Checkboxes
              updateMining={updateMining}
              updateCommodity={updateCommodity}
            />
          ) : null}
          <SearchTabs searchTerm={searchTerm || parsedSearchKeyword} />
        </div>
      </div>
      <div className="searchViewBody">
        {isSearching ? (
          <SearchViewSkeleton />
        ) : (
          <SearchViewContent
            totalItems={
              currentRegulationType === "regulations"
                ? regulationsData?.numFound
                : currentRegulationType === "guidance"
                ? guidanceData?.numFound
                : 0
            }
            solrResultsItems={getSolrResultsItems(
              regulationsData,
              guidanceData
            )}
            searchTerm={searchTerm}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={handleItemsPerPageChange}
            setCurrentPage={handlePageChange}
            currentPage={currentPage}
            renderCoPilot={
              <SearchViewCoPilot
                key={aiResult?.text}
                downloadPath="/documents/"
                aiResult={aiResult}
                aiText={aiResult?.text}
                aiFetching={aiFetching}
                aiResultId={aiResultId}
                updateAIFeedback={(payload: any) => {
                  dispatch(updateAIFeedbackAction(payload));
                }}
                renderSkeleton={<SearchViewSkeleton />}
                feedback={feedback}
                setParentState={setMyState}
                feedbackAttributeName="feedback"
                preSearchPrompt="Run a search and MSHA AI will respond."
                aiQuotedNameString="MSHA Copilot"
              />
            }
          />
        )}
      </div>

      {/* <div>
      {shouldUseAI() && (
        <AIResponseBlock
          downloadPath="/caselaw/"
          aiResult={aiResultCaseLawState}
          aiText={aiResultCaseLawState?.text}
          aiFetching={aiCaseLawFetching}
          aiResultId={aiResultCaseLawId}
          updateAIFeedback={(payload: any) => {
            dispatch(updateAIFeedbackAction(payload));
          }}
          feedback={feedbackCaseLaw}
          setParentState={setMyState}
          feedbackAttributeName="feedbackCaseLaw"
          preSearchPrompt="Run a search and MSHA CASE LAW AI will respond."
          aiQuotedNameString="MSHA Case Law Copilot"
        />
      )}
      <div style={{ display: documentView ? "none" : "inherit" }}>
        {mainState?.solrResults?.response && (
          <SearchViewTabs
            docCount={mainState.solrResults?.response?.numFound || 0}
            caseLawCount={mainState.caseLawResults?.response?.numFound || 0}
            regulationCount={
              mainState?.solrRegulations?.response?.numFound || 0
            }
            onTabChange={(event: any, newValue: any) => {
              switch (newValue) {
                case 0:
                  setSectionTab(SECTION_TAB.REGULATIONS);
                  break;
                case 1:
                  setSectionTab(SECTION_TAB.DOCUMENTS);
                  break;
                case 2:
                  setSectionTab(SECTION_TAB.CASE_LAW);
                  break;
              }
            }}
            currentTab={sectionTab}
            documentContent={
              <div>
                {mainState?.solrResults?.response && (
                  <section className="section">
                    <SolrResultList
                      resetAllEntities={resetAllEntities}
                      isSearching={isSearching}
                      context={"documents"}
                      items={mainState.solrResults.response.docs}
                      term={searchedTerm}
                      facets={mainState.solrResults.facet_counts.facet_fields}
                      singleDocView={(item: any) =>
                        singleDocView(item, "documents")
                      }
                      updateAuthorityLevel={updateAuthorityLevel}
                      updateGuidance={updateGuidance}
                      numFound={mainState.solrResults.response.numFound}
                      solrQuick={mainState.solrQuick}
                      sort={sort}
                      updateSort={updateSort}
                      numOfResults={numOfResults}
                      updateNumOfResults={updateNumOfResults}
                      updateDocType={updateDocType}
                      updateRegsType={updateRegsType}
                      updatePage={updatePage}
                      offset={offset}
                      authorityLevel={authorityLevel}
                      subGuidance={subGuidance}
                      regulations={mainState.solrRegulations}
                      regList={regListItems}
                      regsFacet={regulationsList}
                      entityPerson={entityPerson}
                      entityLocation={entityLocation}
                      entityOrganization={entityOrganization}
                      updateEntityPerson={updateEntityPerson}
                      updateEntityLocation={updateEntityLocation}
                      updateEntityOrganization={updateEntityOrganization}
                      originalDocType={documentType}
                    />
                  </section>
                )}
                {sharedSearchFooterContent()}
              </div>
            }
            regulationsContent={
              <div>
                {mainState?.solrResults?.response && (
                  <section className="section">
                    <SolrResultList
                      resetAllEntities={resetAllEntities}
                      isSearching={isSearching}
                      context={"regulations"}
                      items={mainState.solrResults.response.docs}
                      term={searchedTerm}
                      facets={mainState.solrResults.facet_counts.facet_fields}
                      singleDocView={(item: any) =>
                        singleDocView(item, "documents")
                      }
                      updateAuthorityLevel={updateAuthorityLevel}
                      updateGuidance={updateGuidance}
                      numFound={mainState.solrResults.response.numFound}
                      solrQuick={mainState.solrQuick}
                      sort={sort}
                      updateSort={updateSort}
                      numOfResults={numOfResults}
                      updateNumOfResults={() => {}}
                      updateDocType={updateDocType}
                      updateRegsType={updateRegsType}
                      updatePage={updatePage}
                      offset={offset}
                      authorityLevel={authorityLevel}
                      subGuidance={subGuidance}
                      regulations={mainState.solrRegulations}
                      regList={regListItems}
                      regsFacet={regulationsList}
                      entityPerson={entityPerson}
                      entityLocation={entityLocation}
                      entityOrganization={entityOrganization}
                      updateEntityPerson={updateEntityPerson}
                      updateEntityLocation={updateEntityLocation}
                      updateEntityOrganization={updateEntityOrganization}
                      originalDocType={documentType}
                    />
                  </section>
                )}
                {sharedSearchFooterContent()}
              </div>
            }
            caseLawContent={
              <div>
                {mainState?.caseLawResults?.response && (
                  <section className="section">
                    <SolrResultList
                      resetAllEntities={resetAllEntities}
                      isSearching={isSearchingCaselaw}
                      context={"caselaw"}
                      items={mainState.caseLawResults.response.docs}
                      term={searchedTerm}
                      facets={
                        mainState.caseLawResults.facet_counts.facet_fields
                      }
                      singleDocView={(item: any) =>
                        singleDocView(item, "caselaw")
                      }
                      updateAuthorityLevel={[]}
                      updateGuidance={updateGuidance}
                      numFound={mainState.caseLawResults.response.numFound}
                      solrQuick={[]}
                      sort={sort}
                      updateSort={updateSort}
                      numOfResults={caseLawNumOfResults}
                      updateNumOfResults={updateCaseLawNumOfResults}
                      updateDocType={updateDocType}
                      updateRegsType={updateRegsType}
                      updatePage={caseLawUpdatePage}
                      offset={caseLawOffset}
                      authorityLevel={[]}
                      subGuidance={subGuidance}
                      regulations={mainState.solrRegulations}
                      regList={regListItems}
                      regsFacet={regulationsList}
                      entityPerson={entityPersonCaseLaw}
                      entityLocation={entityLocationCaseLaw}
                      entityOrganization={entityOrganizationCaseLaw}
                      updateEntityPerson={updateEntityPersonCaseLaw}
                      updateEntityLocation={updateEntityLocationCaseLaw}
                      updateEntityOrganization={updateEntityOrganizationCaseLaw}
                      originalDocType={documentType}
                    />
                  </section>
                )}
                {sharedSearchFooterContent()}
              </div>
            }
          />
        )}
      </div>

       */}

      {/* {documentView && (
        <div>
          <div className="NavControls">
            <Typography noWrap className="docNavBar" onClick={backToSearch}>
              Back to Search Results
            </Typography>
            <Button
              className="printButton"
              target="_blank"
              href={documentState}
            >
              Print
            </Button>
          </div>
          <hr />
          <iframe
            title="PDF Viewer"
            className="pdfViewer"
            src={`${REACT_APP_SERVER_DOMAIN}/viewer/viewer.html?file=${encodeURIComponent(
              documentState
            )}#search=${searchedTerm}&phrase=true`}
          />
        </div>
      )} */}
    </div>
  );
};

export default SearchView;
